<template>
    <div>
        <v-alert
            class="mt-3"
            border="top"
            color="error"
            dark
            icon="mdi-alert-circle-check"
            prominent
        >
            <div
                id="pca-disclosure"
                v-html="$t('applications.non-applicant-alert')"
            ></div>
        </v-alert>
        <v-card class="pa-0 py-0 mt-2 mb-3">
            <v-card-title class="mb-1 py-0">{{
                $t("applications.application-processing-notice")
            }}</v-card-title>
            <v-card-text class="my-0 py-0">
                <v-radio-group v-model="process">
                    <v-radio
                        :label="$t('applications.change-to-financial')"
                        :value="false"
                    ></v-radio>
                    <v-radio
                        :label="$t('applications.continue-non-financial')"
                        :value="true"
                    ></v-radio>
                </v-radio-group>

                <v-alert
                    dense
                    color="accent"
                    outlined
                    v-if="hasPending"
                    v-html="
                        $t('applications.pending-search-count', {
                            count: pendingCount,
                        })
                    "
                >
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    large
                    color="primary"
                    @click="continueFromProcessingNotice"
                >
                    <v-icon class="mr-2">mdi-clipboard-account</v-icon>
                    {{ continueLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
// TODO: CHANGE WHEN DONE DEBUGGING
const DEFAULT_PENDING_SEARCH_COUNT = 4;

export default {
    name: "applicant-type-wizard",
    components: {},
    props: {
        applicantType: {
            type: String,
            required: true,
        },
        property: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            process: true,
        };
    },
    computed: {
        continueLabel() {
            return this.process
                ? this.$t("applications.continue-application")
                : this.$t("applications.change-application");
        },
        hasPending() {
            return (
                this.$_.get(
                    this.property,
                    "pending_search_count",
                    DEFAULT_PENDING_SEARCH_COUNT
                ) >=
                this.$config(
                    "applications.nonApplicantSettings.otherPeopleViewingMin"
                )
            );
        },
        pendingCount() {
            return this.$_.get(
                this.property,
                "pending_search_count",
                DEFAULT_PENDING_SEARCH_COUNT
            );
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        continueFromProcessingNotice() {
            if (this.process) {
                // we are processing the wizard for non-applicant
                this.$emit("search");
            } else {
                this.$emit("change", "Applicant");
            }
        },
    },
};
</script>

<style scoped>
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          staticClass: "mt-3",
          attrs: {
            border: "top",
            color: "error",
            dark: "",
            icon: "mdi-alert-circle-check",
            prominent: ""
          }
        },
        [
          _c("div", {
            attrs: { id: "pca-disclosure" },
            domProps: {
              innerHTML: _vm._s(_vm.$t("applications.non-applicant-alert"))
            }
          })
        ]
      ),
      _c(
        "v-card",
        { staticClass: "pa-0 py-0 mt-2 mb-3" },
        [
          _c("v-card-title", { staticClass: "mb-1 py-0" }, [
            _vm._v(_vm._s(_vm.$t("applications.application-processing-notice")))
          ]),
          _c(
            "v-card-text",
            { staticClass: "my-0 py-0" },
            [
              _c(
                "v-radio-group",
                {
                  model: {
                    value: _vm.process,
                    callback: function($$v) {
                      _vm.process = $$v
                    },
                    expression: "process"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("applications.change-to-financial"),
                      value: false
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t("applications.continue-non-financial"),
                      value: true
                    }
                  })
                ],
                1
              ),
              _vm.hasPending
                ? _c("v-alert", {
                    attrs: { dense: "", color: "accent", outlined: "" },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("applications.pending-search-count", {
                          count: _vm.pendingCount
                        })
                      )
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { large: "", color: "primary" },
                  on: { click: _vm.continueFromProcessingNotice }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-clipboard-account")
                  ]),
                  _vm._v(" " + _vm._s(_vm.continueLabel) + " ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }